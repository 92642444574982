import { Html5QrcodeScanner, Html5QrcodeScanType, Html5QrcodeSupportedFormats } from 'html5-qrcode';
import { useEffect, useRef } from 'react';

const qrcodeRegionId = "html5qr-code-full-region";

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props) => {
    let config = {};
    if (props.fps) {
      config.fps = props.fps;
    }
    if (props.qrbox) {
      config.qrbox = props.qrbox;
    }
    if (props.aspectRatio) {
      config.aspectRatio = props.aspectRatio;
    }
    if (props.disableFlip !== undefined) {
      config.disableFlip = props.disableFlip;
    }
  // Only support camera scan type.
    config.supportedScanTypes = [Html5QrcodeScanType.SCAN_TYPE_CAMERA]
    config.formatsToSupport = [Html5QrcodeSupportedFormats.QR_CODE]
    return config;
};

const Html5QrcodePlugin = (props) => {

  const camRef = useRef()

  useEffect(() => {
    // when component mounts
    const config = createConfig(props);
    const verbose = props.verbose === true;
    // Suceess callback is required.
    if (!(props.qrCodeSuccessCallback)) {
      throw Error("qrCodeSuccessCallback is required callback.");
    }
    camRef.current = new Html5QrcodeScanner(qrcodeRegionId, config, verbose);
    camRef.current.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback);
    const element = document.querySelector('.html5-qrcode-element');
    if (element) {
      element.classList.add('btn', 'btn-lg', 'txt-upper', 'btn-primary');
      // element.click()
    }
    // cleanup function when component will unmount
    return () => {
      camRef.current.clear().catch(error => {
        console.error("Failed to clear html5QrcodeScanner. ", error);
      });
    };
  }, []);

    return (
      <div id={qrcodeRegionId} />
    );
};

export default Html5QrcodePlugin;