import React, { useState } from "react";
import Html5QrcodePlugin from "../components/Html5QrcodePlugin";
import { Modal, Spinner } from "react-bootstrap";
import axios from "axios";
import _ from "lodash";

const Scanner = ({ onResult, ...rest }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [scanned, setScanned] = useState(null)

  const onScanSuccess = (decodedText, decodedResult) => {
    setLoading(true)
    setScanned(decodedText)
    const { REACT_APP_API_BASE, REACT_APP_API_KEY } = process.env
    axios.get(`${REACT_APP_API_BASE}/loyalty/fetch/${decodedText}`, {
      headers: {
        Authorization: `Bearer ${REACT_APP_API_KEY}`
      }
    }).then(({ data }) => {
      setLoading(false)
      setError(null)
      const result = _.get(data, 'result')
      const errors = _.get(data, 'errors')
      if (result) {
        onResult(result)
      } else if (errors && errors.length > 0) {
        setError(errors[0])
      } else {
        setError('No Result Found, Please try again.')
      }
    }).catch(ex => {
      setLoading(false)
      setError(null)
      if (ex.response && ex.response) {
        const errors = _.get(ex, 'response.data.errors')
        if (errors && errors.length > 0) {
          setError(errors[0])
        } else {
          setError(ex.message)
        }
      }
    })
  }

  // useEffect(() => {
  //   if (rest.show) {
  //     setTimeout(() => {
  //       onScanSuccess('5535d32e-bb6d-4b7e-a4df-4f295f0ae5bf')
  //     }, 2000)
  //   }
  // }, [rest.show])

  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {!loading ? error ? error : 'SCAN QR CODE' : <><Spinner /> Fetching Details...</>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {scanned ? `Found: ${scanned}` : null}
        <Html5QrcodePlugin qrCodeSuccessCallback={onScanSuccess} />
      </Modal.Body>
    </Modal>
  )
}

export default Scanner