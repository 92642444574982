import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import Scanner from './containers/Scanner';
import axios from 'axios';
import _ from 'lodash';
import PinInput from 'react-pin-input';

function App() {
  const [modalShow, setModalShow] = useState(false);
  const [result, setResult] = useState(null);

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  // const [action, setAction] = useState(null)
  const [user, setUser] = useState(null)
  const [userError, setUserError] = useState(null)

  const onResult = (result) => {
    setResult(result)
    setModalShow(false)
  }

  const submitAction = (uuid, status) => {
    setLoading(true)
    const { REACT_APP_API_BASE, REACT_APP_API_KEY } = process.env
    axios.post(`${REACT_APP_API_BASE}/loyalty/action/${uuid}`, {
      status,
      u: user
    }, {
      headers: {
        Authorization: `Bearer ${REACT_APP_API_KEY}`
      }
    }).then(({ data }) => {
      setError(null)
      setLoading(false)
      const result = _.get(data, 'result')
      const errors = _.get(data, 'errors')
      if (result) {
        setResult(result)
      } else if (errors && errors.length > 0) {
        setError(errors[0])
      } else {
        setError('No Result Found, Please try again.')
      }
    }).catch(ex => {
      setError(null)
      setLoading(false)
      if (ex.response && ex.response) {
        const errors = _.get(ex, 'response.data.errors')
        if (errors && errors.length > 0) {
          setError(errors[0])
        } else {
          setError(ex.message)
        }
      }
    })
  }

  return (
    <Container fluid={true}>
      <div className='text-center' style={{marginBottom: '5rem'}}>
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Trade Centre For 2 Rewards</h1>
      </div>
      {/* <AuthModal /> */}
      {result && result.status ?
        <Row>
          <Col xs={12}>
            <h2>REWARD: <span style={{color: result.status_color}}>{result.status_title.replace(/_/ig, ' ').toUpperCase()}</span></h2>
          </Col>
          <Col xs={12} md={6}><b>Branch:</b> {result.branch}</Col>
          {result.logger_id ? <Col xs={12} md={6}><b>Logger ID:</b> {result.logger_id}</Col> : null}
          <Col xs={12} md={6}></Col>
          <Col xs={12} md={6}><b>Name:</b> {result.title} {result.first_name} {result.last_name}</Col>
          <Col xs={12} md={6}><b>Telephone:</b> {result.telephone}</Col>
          <Col xs={12} md={6}><b>Email:</b> {result.email}</Col>
          <Col xs={12} md={6}></Col>
          <Col xs={12} md={6}><b>Address:</b> {result.address}</Col>
          <Col xs={12} md={6}><b>Postcode:</b> {result.postcode}</Col>
          <Col xs={12} md={6}><b>Status:</b> {result.status.replace(/_/ig, ' ').toUpperCase()}</Col>
          {loading ? <Col xs={12}><Alert variant={'primary'}>Submitting...</Alert></Col> : null}
          {error ? <Col xs={12}><Alert variant={'danger'}>{error}</Alert></Col> : null}
          {result.allowed_transitions && result.allowed_transitions.length > 0 ? 
            <>
              {result.allowed_transitions.indexOf('pack_provided') > -1 ? 
                <Col xs={12} md={12}>
                  <Button size="lg" style={{width: '100%', flexGrow: 1, marginTop: 12}} variant='success' onClick={() => {
                    submitAction(result.uuid, 'pack_provided')
                  }}>PACK PROVIDED</Button>
                </Col>
              : null}
              {result.allowed_transitions.indexOf('cancel') > -1 ? 
                <Col xs={12} md={12}>
                  <Button size="lg" style={{width: '100%', flexGrow: 1, marginTop: 12}} variant='light' onClick={() => {
                    setResult(null)
                    setModalShow(false)
                  }}>CANCEL</Button>
                </Col>
              : null}  
              {result.allowed_transitions.indexOf('next_scan') > -1 ? 
                <Col xs={12} md={12}>
                  <Button size="lg" style={{width: '100%', flexGrow: 1, marginTop: 12}} variant='primary' onClick={() => {
                    setResult(null)
                    setModalShow(true)
                  }}>NEXT SCAN</Button>
                </Col>
              : null}  
            </>
          : null}
        </Row>
      : (
        <Row>
          {!user ? 
          <Col xs={12}>
            <h4 style={{textAlign: 'center'}}>Enter PIN</h4>
            <PinInput 
              length={4} 
              initialValue=""
              secret
              secretDelay={100} 
              onChange={(value, index) => {}} 
              type="numeric" 
              inputMode="number"
              style={{padding: '10px', display: 'flex', justifyContent: 'center'}}  
              // inputStyle={{borderColor: 'red'}}
              // inputFocusStyle={{borderColor: 'blue'}}
              onComplete={(value, index) => {
                if (value && value.length === 4) {
                  const i = ['3454', '9582', '3028', '8273', '2365', '9237', '3892'];
                  const inx = i.indexOf(value);
                  if (inx > -1) {
                    setUser(i[inx])
                    setUserError(null)
                  } else {
                    setUserError('Invalid Pin Entered')
                  }
                  // console.log(1234, value, index)
                }
              }}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
            <div style={{textAlign: 'center', color: 'red', fontWeight: 'bold'}}>{userError}</div>
          </Col>
          : null}
          {user ? 
            <Col xs={12}>
              <Button size="lg" style={{
                // width: '100%', 
                // marginBottom: 12
                position: 'absolute',
                top: '50%',
                left: '12px',
                right: '12px',
                width: 'auto',
              }} onClick={() => {
                setResult(null)
                setModalShow(true)
              }}>SCAN QR CODE</Button>
            </Col>
          : null}
        </Row>
      )}
      <Scanner 
        show={modalShow}
        onResult={onResult}
        onHide={() => setModalShow(false)}
      />
    </Container>
  );
}

export default App;